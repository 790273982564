import React from 'react';

import { Layout } from '../../components/shared/Layout';
import { Seo } from '../../components/shared/Seo';
import { Button } from '../../components/shared/Button';
import { ButtonContentWithIcon } from '../../components/shared/ButtonContentWithIcon';
import { useGTMEvent } from '../../hooks/useGTMEvent';

import speedmeter from '../../images/index/speedmeter.svg';
import stopwatch from '../../images/index/stopwatch.svg';
import label from '../../images/index/label.svg';
import lock from '../../images/index/lock.svg';
import arrowRight from '../../images/icons/arrow-right.svg';

import imageSrc from '../../images/index/bg-couple.jpg';

export const Index = () => {
  const pushHeaderGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:header',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_header',
  });

  const pushBenefitsGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:benefits',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_benefits',
  });

  const pushFooterGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:footer',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_footer',
  });

  return (
    <Layout
      logoVariant="light"
      loginVariant="light"
      footerVariant="light"
      showLoginButton
      noVerticalPadding
    >
      <Seo title="Zjistěte, jak Vás vidí banky" />

      <section
        className="pt-48 pb-32 px-4 text-white bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(44.94deg, rgba(23, 30, 83, 0.7) 2.7%, rgba(0, 15, 134, 0.7) 53.86%, rgba(127, 49, 204, 0.7) 114.13%), url(${imageSrc})`,
        }}
      >
        <div className="md:grid grid-cols-2 gap-x-4 mx-auto max-w-screen-xl">
          <div className="space-y-8">
            <h1
              className="text-6xl font-bold leading-none"
              data-testid="index-title"
            >
              Běžte do&nbsp;banky připraveni
            </h1>
            <h2 className="text-2xl sm:max-w-lg" data-testid="index-subtitle">
              Díky Osobnímu skóre zjistíte, jak budou finanční instituce
              hodnotit Vaši úvěruschopnost. Nechte si zdarma spočítat, za jaký
              úrok můžete získat půjčku.
            </h2>
            <div data-testid="index-button-want-find-my-score-first">
              <ButtonContentWithIcon
                url="/ucet/registrace"
                label="chci zjistit své skóre"
                iconSrc={arrowRight}
                onClick={pushHeaderGTMEvent}
              />
            </div>
          </div>
          <div className="hidden md:block">
            <img src={speedmeter} alt="Speedmeter" aria-hidden="true" />
          </div>
        </div>
      </section>

      <section className="py-32 px-4">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2
            className="text-4xl tracking-wide"
            data-testid="index-second-title"
          >
            Zjistěte, jak Vás hodnotí finanční instituce
          </h2>
          <div className="grid grid-cols-1 grid-rows-3 sm:grid-cols-3 sm:grid-rows-1 sm:gap-x-8 md:gap-x-16 gap-y-8 mt-16 mb-12">
            <div>
              <img
                className="mx-auto max-w-xs"
                src={stopwatch}
                alt="Rychle"
                aria-hidden="true"
              />
              <p
                className="mb-6 text-2xl uppercase"
                data-testid="index-title-rychle"
              >
                Rychle
              </p>
              <p className="text-xl" data-testid="index-body-rychle">
                Za pár minut spočítáme, na jaký úrok&nbsp;byste mohli dosáhnout.
              </p>
            </div>
            <div>
              <img
                className="mx-auto max-w-xs"
                src={label}
                alt="Zdarma"
                aria-hidden="true"
              />
              <p
                className="mb-6 text-2xl uppercase"
                data-testid="index-title-zdarma"
              >
                Zdarma
              </p>
              <p className="text-xl" data-testid="index-body-zdarma">
                Vyhodnocení Osobního skóre od nás&nbsp;dostanete zdarma.
              </p>
            </div>
            <div>
              <img
                className="mx-auto max-w-xs"
                src={lock}
                alt="Diskrétně"
                aria-hidden="true"
              />
              <p
                className="mb-6 text-2xl uppercase"
                data-testid="index-title-diskretne"
              >
                Diskrétně
              </p>
              <p className="text-xl" data-testid="index-body-diskretne">
                Chráníme Vaše soukromí. Výsledné skóre&nbsp;proto uvidíte jen
                Vy.
              </p>
            </div>
          </div>
          <Button
            to="/ucet/registrace"
            color="secondary"
            border="normal"
            onClick={pushBenefitsGTMEvent}
            data-testid="index-button-want-find-my-score-second"
          >
            chci zjistit své skóre
          </Button>
        </div>
      </section>

      <section className="pt-24 pb-40 px-4 xl:px-0 bg-tips-pattern bg-cover bg-secondary text-white">
        <div className="sm:grid grid-cols-2 gap-x-4 mx-auto max-w-screen-lg">
          <div className="sm:w-64 sm:mr-auto">
            <h2 className="text-6xl font-bold" data-testid="index-third-title">
              Co Vám banky jen tak neřeknou
            </h2>
          </div>
          <div className="space-y-8">
            <div>
              <span className="mr-2 text-4xl font-bold">01</span>
              <span
                className="text-xl tracking-wider"
                data-testid="index-first-col-in-third-part"
              >
                Osobní skóre funguje na principu výpočtu kreditního skóre
                a&nbsp;slouží k vyhodnocení finanční důvěryhodnosti. Kreditní
                skóre v&nbsp;různých formách používají bankovní
                i&nbsp;nebankovní společnosti pro vyčíslení úroku žadatele
                o&nbsp;půjčku nebo hypotéku.
              </span>
            </div>
            <div>
              <span className="mr-2 text-4xl font-bold">02</span>
              <span
                className="text-xl tracking-wider"
                data-testid="index-second-col-in-third-part"
              >
                Díky Osobnímu skóre zjistíte, za jaký úrok si můžete půjčit.
                Dopředu tak budete vědět, jaké máte při půjčování možnosti,
                a&nbsp;zvolíte tu nejvýhodnější variantu.
              </span>
            </div>
            <div>
              <span className="mr-2 text-4xl font-bold">03</span>
              <span
                className="text-xl tracking-wider"
                data-testid="index-third-col-in-third-part"
              >
                Všechno navíc zařídíte online a&nbsp;zabere Vám to jen pár
                minut.
              </span>
            </div>
            <div
              className="pt-16"
              data-testid="index-button-want-find-my-score-third"
            >
              <ButtonContentWithIcon
                url="/ucet/registrace"
                label="chci zjistit své skóre"
                iconSrc={arrowRight}
                onClick={pushFooterGTMEvent}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
